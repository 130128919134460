import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseClass } from '../../base-class/base-class';
import { IbaseService } from '../../base-service/ibase-service';
import { WebserviceService } from '../../services/webservice.service';

@Component({
  selector: 'app-market-rule',
  template: `
  <app-m-market-rule *ngIf="env.isMobile"></app-m-market-rule>
  <app-d-market-rule *ngIf="!env.isMobile"></app-d-market-rule>
  `,
  styles: []
})
export class MarketRuleComponent extends BaseClass<any> implements OnInit {
  public key = 'b';
  constructor(public bsModalRef: BsModalRef,public service: WebserviceService) { super(document); }

  ngOnInit(): void {
  }

  getServices(): IbaseService<any> {
    return this.service;
  }

  close() {
    this.bsModalRef.hide();
  }

}
