import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BetSlipComponent } from '../bet-slip.component';

@Component({
  selector: 'app-d-bet-slip',
  templateUrl: './d-bet-slip.component.html',
  styleUrls: ['./d-bet-slip.component.css']
})
export class DBetSlipComponent extends BetSlipComponent implements OnInit {
  @Input() data!: any;
  @Input() exposLisDis!: any;
  @Output() closeBet = new EventEmitter();
  @Output() updateData = new EventEmitter();
  @Output() changeExpo = new EventEmitter();

  ngOnInit(): void {
  }

  ngOnChanges() {    
    this.betData = this.data; 
    this.expo = this.exposLisDis; 
    this.setDataOnChange(this.data, this.exposLisDis)   
  }

}
