import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/@shared/services/shared.service';
import { WebserviceService } from 'src/app/@shared/services/webservice.service';
import { BetListComponent } from '../bet-list.component';

@Component({
  selector: 'app-m-bet-list',
  templateUrl: './m-bet-list.component.html',
  styleUrls: ['./m-bet-list.component.css']
})
export class MBetListComponent extends BetListComponent implements OnInit {
  @Output() totalBets = new EventEmitter();

  constructor(public service: WebserviceService,
    protected actRoute: ActivatedRoute,
    protected _shared: SharedService
  ) {
    super(service, actRoute, _shared);
    this.constructorCallData();
  }

  ngOnInit(): void {
    this.getData();
  }

}
