import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseClass } from '../../base-class/base-class';
import { IbaseService } from '../../base-service/ibase-service';
import { WebserviceService } from '../../services/webservice.service';

@Component({
  selector: 'app-rules',
  template: `
  <app-m-rules *ngIf="env.isMobile"></app-m-rules>
  <app-d-rules *ngIf="!env.isMobile"></app-d-rules>
  `,
  styles: []
})
export class RulesComponent extends BaseClass<any> implements OnInit {

  constructor(public bsModalRef: BsModalRef, public service: WebserviceService) { super(document);}

  ngOnInit(): void {
  }

  getServices(): IbaseService<any> {
    return this.service;
  }

  close() {
    this.bsModalRef.hide();
  }

}
