import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { LoginComponent } from '../login.component';

@Component({
  selector: 'app-d-login',
  templateUrl: './d-login.component.html',
  styleUrls: ['./d-login.component.css']
})
export class DLoginComponent extends LoginComponent implements OnInit {
  public key: any;

  ngOnInit(): void {
    if (this.key) {
      this.bsModalRef = this.injector.get<BsModalRef>(BsModalRef);
    }
    if (this.key?.signUpModal) { this.communicateiframe(); }
    this.initForm();
  }
  communicateiframe() {
    window.onmessage = (e) => {
      if (e.data?.status == true) {
        if (e.data?.redirect == 'home') {
          this.router.navigate(['/home']);
        }
        if (e.data?.redirect == 'login') {
          if (this.key) { this.bsModalRef?.hide(); }
          this._common.openLogin();
          //this.router.navigate(['/home'], { queryParams: { login: true } });
        } else {
          let req = {
            "username": e.data?.username,
            "password": e.data?.password,
            "domain": this.env.domain
          }
          this.onLogin('demo', req);
        }
      }
    };
  }

  // commIf() {
  //   window.onmessage = (e) => {
  //     if (e.data?.status == true) {
  //        if (e.data?.redirect == 'login') {
  //         console.log(7878);
          
  //         if (this.key) { this.bsModalRef?.hide(); }
  //         this._common.openLogin();
  //       } 
  //     }
  //   };
  //   this.communicateiframe();
  // }

  ngAfterViewInit() {
    if (!this.key) {
      this._loader.initiateLoader();
      this._loader.hideLoader();
    }
  }

}
