import { Component, OnInit } from '@angular/core';
import { MarketRuleComponent } from '../market-rule.component';

@Component({
  selector: 'app-m-market-rule',
  templateUrl: './m-market-rule.component.html',
  styleUrls: ['./m-market-rule.component.css']
})
export class MMarketRuleComponent extends MarketRuleComponent implements OnInit {

  ngOnInit(): void {
  }

}
