import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  constructor() {}

  initiateLoader() {
    const css = 'visibility: visible; opacity: 1;';
    this.loader(css);
  }

  hideLoader() {
    const css = 'visibility: hidden; opacity: 0;';
    setTimeout(() => this.loader(css), 500);
  }

  loader(css:any) {
    const element = document.getElementById('load');
    console.log(element);
    
    if (element) {
      element.style.cssText = css;
    }
  }
}
