<div class="report-container">
    <div class="card">
      <div class="card-header">
        <h4 class="mb-0">Change Password</h4>
      </div>
      <div class="card-body container-fluid container-fluid-5">
        <form [formGroup]="formData" (ngSubmit)="onSubmit()">
          <div class="row row5 mt-2">
            <div class="col-12">
              <div class="form-group">
                <label>Current Password</label>
                <input formControlName="old_password" type="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.old_password.errors }">

                <div *ngIf="submitted && f.old_password.errors" class="invalid-feedback">
                  <div *ngIf="f.old_password.errors.required">The Old Password field is required</div>
                </div>

              </div>

              <div class="form-group">
                <label>New Password</label> 
                <input formControlName="new_password" type="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.new_password.errors }">

                <div *ngIf="submitted && f.new_password.errors" class="invalid-feedback">
                  <div *ngIf="f.new_password.errors.required">The New Password field is required</div>
                  <div *ngIf="f.new_password.errors.minlength">The New Password field must be at least 6 characters
                  </div>
                  <div *ngIf="!f.new_password.errors.minlength && f.new_password.errors.pattern">The New Password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number</div>
                </div>

              </div>

              <div class="form-group">
                <label>Confirm New Password</label>
                <input formControlName="new_password_re" type="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.new_password_re.errors }">

                <div *ngIf="submitted && f.new_password_re.errors" class="invalid-feedback">
                  <div *ngIf="f.new_password_re.errors.required">The Confirm Password field is required</div>
                  <div *ngIf="f.new_password_re.errors.mustMatch">The Confirm Password confirmation does not match</div>
                </div>

              </div>
              
            </div>
          </div>
          <div class="row row5 mt-2">
            <div class="col-12"><button type="submit" class="btn btn-primary btn-block btn-sm">Change Password</button></div>
          </div>
        </form>
      </div>
    </div>
</div>

<app-loader *ngIf="isLoad" [isFpLoader]="false"></app-loader>