import { Component, OnInit } from '@angular/core';
import { MarketRuleComponent } from '../market-rule.component';

@Component({
  selector: 'app-d-market-rule',
  templateUrl: './d-market-rule.component.html',
  styleUrls: ['./d-market-rule.component.css']
})
export class DMarketRuleComponent extends MarketRuleComponent implements OnInit {

  ngOnInit(): void {
  }

}
