import { Component, OnInit } from '@angular/core';
import { StakeComponent } from '../stake.component';

@Component({
  selector: 'app-m-stake',
  templateUrl: './m-stake.component.html',
  styleUrls: ['./m-stake.component.css']
})
export class MStakeComponent extends StakeComponent implements OnInit {

  ngOnInit(): void {
    this.init();
  }

}
