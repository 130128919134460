import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from 'src/app/@shared/services/shared.service';
import { WebserviceService } from 'src/app/@shared/services/webservice.service';
import { BetListComponent } from '../bet-list.component';

@Component({
  selector: 'app-d-bet-list',
  templateUrl: './d-bet-list.component.html',
  styleUrls: ['./d-bet-list.component.css']
})
export class DBetListComponent extends BetListComponent implements OnInit {

  constructor(public service: WebserviceService,
    protected actRoute: ActivatedRoute,
    protected _shared: SharedService
  ) {
    super(service, actRoute, _shared);
    this.constructorCallData();
  }

  ngOnInit(): void {
    this.getData();
  }

}
