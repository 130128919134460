<div class="report-container">
    <div class="card">
      <div class="card-header">
        <h4 class="mb-0">Change Button Values</h4>
      </div>
      <div class="card-body container-fluid container-fluid-5 button-value">
        <div class="row row5 mb-1">
          <div class="col-6">
            <div class="button-title"><span><b>Price Label</b></span></div>
          </div>
          <div class="col-6">
            <div class="button-title"><span><b>Price Value</b></span></div>
          </div>
        </div>
        <div class="row row5 mb-1" *ngFor="let arr of stArr;let i=index;">
          <div class="col-6">
            <div class="form-group mb-0">
                <input [(ngModel)]="stacks['stake_name_'+ (i+1)]" placeholder="Button Name" type="text" maxlength="7" class="form-control">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mb-0">
                <input [(ngModel)]="stacks['stake_amount_'+ (i+1)]" placeholder="Button Value" type="number" min="1" max="99999999" maxlength="9" class="form-control">
            </div>
          </div>
        </div>
        <div class="row row5 mt-2">
          <div class="col-12"><button class="btn btn-primary btn-block btn-sm" (click)="setBtnVal()">Update</button></div>
        </div>
      </div>
    </div>
  </div>

  <app-loader *ngIf="isLoad" [isFpLoader]="false"></app-loader>