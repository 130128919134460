import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class SharedService {
  public message = new BehaviorSubject("");
  sharedMessage = this.message.asObservable();

  public exposer = new BehaviorSubject("");
  sharedExposer = this.exposer.asObservable();

  public betSlipData = new BehaviorSubject("");
  sharedbetSlipData = this.betSlipData.asObservable();

  public betSlipRes = new BehaviorSubject("");
  sharedbetSlipRes = this.betSlipRes.asObservable();

  public tabCasino = new BehaviorSubject("");
  sharedTabCasino = this.tabCasino.asObservable();

  public tabCasinoI = new BehaviorSubject("");
  sharedTabCasinoI = this.tabCasinoI.asObservable();

  public updateExpoPusher = new BehaviorSubject("");
  sharedupdateExpoPusher = this.updateExpoPusher.asObservable();

  public updateBetList = new BehaviorSubject("");
  sharedupdateBetList = this.updateBetList.asObservable();

  // ----------------------------------
  public sideMenu = new BehaviorSubject("");
  sharedSideMenu = this.sideMenu.asObservable();

  public menu = new BehaviorSubject("");
  sharedMenu = this.menu.asObservable();
  
  public tabSet = new BehaviorSubject("");
  sharedTabSet = this.tabSet.asObservable();

  public updateUserAccPusher = new BehaviorSubject("");
  sharedupdateUserAccPusher = this.updateUserAccPusher.asObservable();

  public login = new BehaviorSubject("");
  sharedLogin = this.login.asObservable();

  constructor() { }

  nextMessage(message: any) {
    this.message.next(message);
  }

  emitExposer(data: any) {
    this.exposer.next(data);
  }

  emitbetSlipData(data: any) {
    this.betSlipData.next(data);
  }

  emitbetSlipRes(data: any) {
    this.betSlipRes.next(data);
  }

  emitTabCasino(data: any) {
    this.tabCasino.next(data);
  }

  emitTabCasinoI(data: any) {
    this.tabCasinoI.next(data);
  }

  emitUpdateExpoPusher(data: any) {
    this.updateExpoPusher.next(data);
  }

  emitUpdateBetList(data: any) {
    this.updateBetList.next(data);
  }

  // ---------------------------------
  emitSideMenu(data: any) {
    this.sideMenu.next(data);
  }

  emitMenu(data: any) {
    this.menu.next(data);
  }

  emitTabSet(data: any) {
    this.tabSet.next(data);
  }

  emitUpdateUserAccPusher(data: any) {
    this.updateUserAccPusher.next(data);
  }

  emitLoginData(data: any) {
    this.login.next(data);
  }

}
