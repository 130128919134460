import { Component, OnInit } from '@angular/core';
import { RulesComponent } from '../rules.component';

@Component({
  selector: 'app-d-rules',
  templateUrl: './d-rules.component.html',
  styleUrls: ['./d-rules.component.css']
})
export class DRulesComponent extends RulesComponent implements OnInit {

  ngOnInit(): void {
  }

}
