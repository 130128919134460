
import {DomSanitizer} from '@angular/platform-browser';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({ name: 'safeURL' })
export class SafeURLPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    transform(url:any) {
    console.log('PIPE START Called');
    console.log(url);
    console.log('PIPE END Called');
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
