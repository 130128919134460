<select class="form-control mobile-hide my-1" [(ngModel)]="selectedEvent" [ngClass]="isDetail ? 'd-none' : ''">
  <option [value]="item.id" *ngFor="let item of eventList;trackBy:trackByIndex;">{{item.name}}</option>
</select>

<table class="coupon-table table  table-borderedless">
    <thead>
      <tr>
        <th class="text-left" style="width: 30%;">
          Nation
        </th>
        <th class="text-center">
          Odds
        </th>
        <th class="text-center">
          Stake
        </th>
        <th class="text-left" style="width: 30%;">
          Date/Time
        </th>
      </tr>
    </thead>
    <!-- *ngFor="let item of data?.matched; trackBy:trackByIndex;" -->
    <tr [ngClass]="item.bet_type == 1 ? 'back' : 'lay'" *ngFor="let item of data.matched | filter : {'event_id-eq': selectedEvent}; trackBy:trackByIndex;">
        <td class="text-left" style="width: 30%;">{{item.runner_name}} {{item.market_type}} </td>
        <td class="text-center" *ngIf="item?.betting_type != 'F'">{{item.average_rate_matched | number}}</td>
        <td class="text-center" *ngIf="item?.betting_type == 'F'">{{item.price != 100 ? (item.rate | number)+'/'+(item.price | number) : item.average_rate_matched | number}}</td>
        <td class="text-center">{{item.stake_matched | number}}</td>
        <td class="text-left" style="width: 30%;">{{(item.matched_at_format ? item.matched_at_format : item.matched_at) | date :
          'dd/MM/YYYY hh:mm:ss a'}}</td>
    </tr>

    <tr *ngIf="data && matchedTotal == 0">
      <td colspan="4" class="text-center">No records Found</td>
    </tr>
</table>
