<div [ngClass]="betData.side.includes('b') ? 'back' : 'lay'" class="table-responsive position-relative" *ngIf="betData && betData?.isClosed == false">
    <form>
      <table class="coupon-table table table-borderedless">
        <thead>
          <tr>
            <th></th>
            <th style="width: 35%; text-align: left;">(Bet for)</th>
            <th style="width: 25%; text-align: left;">Odds</th>
            <th style="width: 15%; text-align: left;">Stake</th>
            <!-- <th style="width: 15%; text-align: right;">Profit</th> -->
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="text-center"><a href="javascript:void(0);" (click)="onCloseBet($event)" class="text-danger"><i class="fa fa-times"></i></a></td>
            <td>
                {{betData.name}} 
            </td>
            <td class="bet-odds">
              <div class="form-group">
                <input placeholder="0" type="text" required="required" name="" [(ngModel)]="betData.price" [ngModelOptions]="{standalone: true}" (change)="validateField()" min="1.01" max="999.99" readonly="readonly" class="amountint" style="width: 45px; vertical-align: middle;"> 
                <div class="spinner-buttons input-group-btn btn-group-vertical">
                    <button (click)="onChangeOdds('down')" type="button" class="custom-btn-spinner btn btn-xs btn-default"><i class="fa fa-angle-up"></i></button>
                    <button (click)="onChangeOdds('up')" type="button" class="custom-btn-spinner btn btn-xs btn-default"><i class="fa fa-angle-down"></i></button>
                </div>
              </div>
            </td>
            <td class="bet-stakes">
              <div class="form-group bet-stake">
                <input maxlength="10" required="required" type="number" placeholder="Stake:100" (keypress)="digitOnly($event)" (keydown)="validateField()"
                (keyup)="validateField()" (change)="validateField()" [(ngModel)]="betData.stake" [ngModelOptions]="{standalone: true}" min="0"></div>
            </td>
            <!-- <td class="text-right bet-profit">0</td> -->
          </tr>
          <tr>
            <td colspan="5" class="value-buttons">
                <button type="button" class="btn btn-secondary m-l-5 m-b-5" *ngFor="let stk of betData.stakes;" (click)="onAmountChange(stk)"> 
                    {{stk.name}} 
                </button>
                <button type="button" class="btn btn-secondary m-l-5 m-b-5 min-stake" (click)="onMinMaxAmountChange(betData?.min)">min stake</button>
                <button type="button" class="btn btn-secondary m-l-5 m-b-5 max-stake" (click)="onMinMaxAmountChange(betData?.max)">max stake</button>
                <button class="btn btn-secondary m-l-5 m-b-5 min-stake" style="background: green !important;color: #fff;" (click)="onAllInAmountChange()">all in</button>
                <button class="btn btn-secondary m-l-5 m-b-5 min-stake" style="background: red !important;color: #fff;" (click)="onClear()">clear</button>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-md-12">
        <button type="button" (click)="onCloseBet($event)" class="btn btn-sm btn-danger float-left">
        Reset
        </button> 
        <button type="button" [disabled]="betData.disabled" (click)="orderPlaced()" class="btn btn-sm btn-success float-right m-b-5">
        Submit
        </button>
      </div>
    </form>

    <div id="loadInner" *ngIf="isLoad">
        <div id="load-inner"><i class="fa fa-spinner fa-spin"></i></div>
    </div>

  </div>