<div class="row row5">
    <div class="col-12 account-statement-tbl">
      <div class="table-responsive">
        <table role="table" aria-busy="false" aria-colcount="6" class="table b-table table-striped table-bordered" id="__BVID__69">
          <thead role="rowgroup" class="">
            <!---->
            <tr role="row" class="">
              <th role="columnheader" scope="col" aria-colindex="1" class="text-center" *ngFor="let head of headElements; let i = index">{{head?.value | titlecase}}</th>
            </tr>
          </thead>
          <tbody role="rowgroup">
            <!---->
            <tr [ngClass]="{'back': (el.bet_type == 1 && rowClassEnabled), 'lay': (el.bet_type == 0 && rowClassEnabled)}" role="row" tabindex="0" aria-rowindex="1" class="" *ngFor="let el of elements | searchData : searchDt; let i = index;">
                <td aria-colindex="1" role="cell" class="text-center" *ngFor="let head of headElements; let hi = index">
                    <span *ngIf="head?.type == 'index'">{{getIndex(i)}}</span>
                    <span *ngIf="head?.type == 'string'" [tooltip]="head?.tooltip ? head?.tooltipName+': '+el[head?.tooltip] : ''">{{el[head?.key]}}</span>
                    <span *ngIf="head?.type == 'number'" [ngClass]="el[head?.key] >= 0 ? 'text-success' : 'text-danger'">{{checkIsNumber(el[head?.key]) ? fixedPostDecimal(el[head?.key]) : el[head?.key]}}</span>
                    <span *ngIf="head?.type == 'numberWithModal'" [ngClass]="{'text-success' : el[head?.key] >= 0, 'text-danger' : el[head?.key] < 0}" (click)="openModal.emit(el)">{{checkIsNumber(el[head?.key]) ? fixedPostDecimal(el[head?.key]) : el[head?.key]}}</span>
                    <span *ngIf="head?.type == 'stringWithModal'" >
                      <a *ngIf="!(head?.condition) || (head?.condition && el[head?.condition[head?.type+'']?.key] != head?.condition[head?.type+'']?.value)" (click)="openModal.emit(el)" href="javascript:void();"> {{el[head?.key]}}</a>
                      <span *ngIf="(head?.condition && el[head?.condition[head?.type+'']?.key] == head?.condition[head?.type+'']?.value)">{{el[head?.key]}}</span>
                    </span>
                    <span *ngIf="head?.type == 'date'">{{el[head?.key] | date : 'YYYY-MM-dd HH:mm'}}</span>
                    <div *ngIf="head?.type == 'url'">
                        <a href="javascript:void();" *ngFor="let ln of head?.links;"  [routerLink]="[getUrl(ln, el, head)]"> {{el[head?.key] ? el[head?.key] : ln?.name}}</a>
                    </div>
                </td>
            </tr>
          </tbody>

          <tbody *ngIf="elements && elements.length == 0">
            <tr>
              <td [attr.colspan]="headElements.length">No Data To Display</td>
            </tr>
          </tbody>
          <!---->
        </table>
      </div>
    </div>
    <!---->
  </div>
  <div class="row row5 mt-2" *ngIf="pageData && showPagination">
    <div class="col-12">
      <ul role="menubar" aria-disabled="false" aria-label="Pagination" class="pagination mb-0 b-pagination justify-content-center">
        <li role="presentation" class="page-item" [ngClass]="{'active' : link.active == true, 'disabled': link.url == null}" *ngFor="let link of pageData?.links;trackBy:trackByIndex">
          <a role="menuitemradio"  href="javascript:void(0);"  class="page-link" (click)="changePG(link)" [innerHTML]="link.label"></a></li>
      </ul>
      <!-- <ul role="menubar" aria-disabled="false" aria-label="Pagination" class="pagination mb-0 b-pagination justify-content-center">
        <li role="presentation" aria-hidden="true" class="page-item disabled"><span role="menuitem" aria-label="Go to first page" aria-disabled="true" class="page-link">First</span></li>
        <li role="presentation" aria-hidden="true" class="page-item disabled"><span role="menuitem" aria-label="Go to previous page" aria-disabled="true" class="page-link">Prev</span></li>
        <li role="presentation" class="page-item active"><a role="menuitemradio" aria-label="Go to page 1" aria-checked="true" aria-posinset="1" aria-setsize="1" tabindex="0" target="_self" href="#" class="page-link">1</a></li>
        <li role="presentation" aria-hidden="true" class="page-item disabled"><span role="menuitem" aria-label="Go to next page" aria-disabled="true" class="page-link">Next</span></li>
        <li role="presentation" aria-hidden="true" class="page-item disabled"><span role="menuitem" aria-label="Go to last page" aria-disabled="true" class="page-link">Last</span></li>
      </ul> -->
    </div>
  </div>