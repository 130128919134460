import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BaseClass } from '../../base-class/base-class';
import { IbaseService } from '../../base-service/ibase-service';
import { HeadElement } from '../../model/table';
import { WebserviceService } from '../../services/webservice.service';

@Component({
  selector: 'app-table',
  template: `
  <app-m-table *ngIf="env.isMobile"></app-m-table>
  <app-d-table *ngIf="!env.isMobile"></app-d-table>
  `,
  styles: []
})

export class TableComponent extends BaseClass<any> implements OnInit {
  public env = environment;
  @Output() changePage = new EventEmitter();
  @Output() openModal = new EventEmitter();

  public casinoEventTypeIds: any;
  public callEventTypeApi: boolean = false;
  public rowClassEnabled: boolean = false;
  public showPagination: boolean = true;
  public searchDt: any = {};
  pageData: any;
  elements: any = [];
  headElements: HeadElement[] = [];

  constructor(public service: WebserviceService, protected cdRef: ChangeDetectorRef) { super(document); }

  ngOnInit(): void {
  }

  getServices(): IbaseService<any> {
    return this.service;
  }

  ngAfterViewInit() {
    // this.cdRef.detectChanges();
  }

  getUrl(ln: any, el: any, head: any) {
    let link: any = ln?.url.split(',');
    let ul: any = '';
    if (ln.type == 'bh') {   //bh :- bet History URL
      link[link.length - 1] = this.betHistory(el);
    }

    link.forEach((element: any, i: any) => {
      ul += (i == 0) ? `${element}` : `/${el[element]}`
    });
    return ul
  }

  changePG(e: any) {
    if (e.label != this.pageData.current_page) {
      this.changePage.emit(e.url);
    }
  }

  getIndex(index: any) {
    if (this.pageData) {
      return index + (this.pageData?.from - 1) + 1
    }    
    return Number(index) + 1;
  }

  checkIsNumber(maybeNumber:any) {    
    return isNaN(+maybeNumber) ? false : true;
  }

  getEventTypeIdsData() {
    this.getServices().Get(`${this.env.baseUrl + this.api.eventTypeIds}`).subscribe(
      (res) => {
        if (res.status == true) {
          this.casinoEventTypeIds = res?.data?.event_type_ids;
        }
      },
      (err) => {
        console.log(err);
      });
  }

  betHistory(item: any) {
    let id = '';
    this.casinoEventTypeIds.forEach((element: any) => {
      if (element.id.includes(Number(item.event_type_id))) {
        id = element.url.split('/:')[1];
      }
    });
    if (id == '') { id = 'market_id' }
    return id;
  }

  toWord(e: any) {
    return e.split("_").map((element: any) => {
      return element.charAt(0).toUpperCase() + element.slice(1).toLowerCase();
    }).toString().replace(/,/g, ' ');
  }
}
