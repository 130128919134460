<div class="new-betslip" [ngClass]="betData.side.includes('b') ? 'back' : 'lay'"
    *ngIf="betData && betData?.isClosed == false">
    <div id="goto-{{betData?.market_id}}-{{betData?.selection_id}}"></div>
    <!-- <div class="row mx-0">
        <div class="col-6">MIN:<b>{{kFormatter(betData.data.market.min_bet)}}</b></div>
        <div class="col-6">MAX:<b>{{kFormatter(betData.data.market.max_bet)}}</b></div>
    </div> -->
    <div class="row mx-0">
        <div class="col-6">
            <div class="input-group">
                <div class="input-group-prepend" (click)="onChangeOdds('down')">
                    <span class="input-group-text">-</span>
                </div>
                <input type="number" placeholder="15" class="form-control" [(ngModel)]="betData.price"
                    [ngModelOptions]="{standalone: true}" readonly="readonly" (change)="validateField()" min="1.01"
                    max="999.99">
                <div class="input-group-append" (click)="onChangeOdds('up')">
                    <span class="input-group-text">+</span>
                </div>
            </div>
        </div>
        <div class="col-6">
            <input type="number" placeholder="Stake:100" class="form-control" (keypress)="digitOnly($event)"
                (keydown)="validateField()" (keyup)="validateField()" (change)="validateField()"
                [(ngModel)]="betData.stake" [ngModelOptions]="{standalone: true}">
        </div>
    </div>

    <div class="row mx-0">
        <div class="new-stakes mb-0 col-12 px-0">
            <button class="ot-stake" *ngFor="let stk of betData.stakes;" (click)="onAmountChange(stk)">
                <i class="fa fa-plus"></i> {{stk.name}}
            </button>
            <button class="min-stake" (click)="onMinMaxAmountChange(betData?.min)">min stake</button>
            <button class="max-stake" (click)="onMinMaxAmountChange(betData?.max)">max stake</button>
            <button class="all-in-stake" (click)="onAllInAmountChange()">all in</button>
            <button class="clear-stake" (click)="onClear()">clear</button>
        </div>
    </div>

    <div class="row mx-0">
        <div class="col-6 px-0">
            <button class="new-cancel-button" (click)="onCloseBet($event)">cancel</button>
        </div>
        <div class="col-6 px-0">
            <button class="new-placebet-button" [disabled]="betData.disabled" (click)="orderPlaced()">place
                bet</button>
        </div>
    </div>

    <div id="loadInner" *ngIf="isLoad">
        <div id="load-inner"><i class="fa fa-spinner fa-spin"></i></div>
    </div>
</div>