<header id="__BVID__36___BV_modal_header_" class="modal-header">
    <h5 id="__BVID__36___BV_modal_title_" class="modal-title">Rules</h5><button type="button" (click)="close()"   class="close">×</button>
</header>
<div id="__BVID__36___BV_modal_body_" class="modal-body">
    <div class="container-fluid">
        <div data-typeid="" class="row rules-container">
            <div class="sidebar col-md-2 sidebar-title" style="margin-top: 0px;">
                <ul><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            Football Fancy
                        </li>
                    </a><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            Motor Sport
                        </li>
                    </a><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            Mixed Martial Arts
                        </li>
                    </a><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            Big Bash League
                        </li>
                    </a><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            Lunch Favourite
                        </li>
                    </a><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            Bookmaker
                        </li>
                    </a><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            Teenpatti
                        </li>
                    </a><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            CricketCasino
                        </li>
                    </a><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            Politics
                        </li>
                    </a><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            Fancy Market 1
                        </li>
                    </a><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            IPL
                        </li>
                    </a><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            Kabaddi
                        </li>
                    </a><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            World Cup
                        </li>
                    </a><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            Binary
                        </li>
                    </a><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            Fancy
                        </li>
                    </a><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            Match
                        </li>
                    </a><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            Khado
                        </li>
                    </a><a href="javascript:void(0)" class="text-white rule-type">
                        <li>
                            Election
                        </li>
                    </a></ul>
            </div>
            <div class="col nopading rules-description">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Football Fancy</h4>
                    </div>
                    <div class="card-body">
                        <table class="table table-bordered">
                            <tbody>
                                <tr>
                                    <td><span class="text-danger">Tournament Total Goals, Team Total Goals
                                            goals.scored in 90 minutes or in extra-time will count.Goals scored in
                                            penalty shootouts do not count.</span></td>
                                </tr>
                                <tr>
                                    <td><span class="text-danger">Tournament Corners - Only corners taken in 90
                                            minutes count.</span></td>
                                </tr>
                                <tr>
                                    <td><span class="text-danger">Tournament Penalties Missed/Converted - Penalties
                                            taken in 90 minutes, extra-time and penalty shootouts all count. If a
                                            penalty has to be re-taken the previous disallowed penalty(ies) do not
                                            count.</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>