import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(item: any, args: any): any {
    // console.log(item);

    if (!item) return [];

    Object.keys(args).some(key => {
      let a = key.split('-')[0];  //actual key
      let b = key.split('-')[1];  //filter type eq(equalTo) and in(include)
      args[key] = (args[key] != null) ? args[key] + "".toLowerCase() : null;

      if (args[key] != '' && args[key] != null) {
        if (b == 'eq') {
          item = [
            ...item.filter((val: any) =>
              val[a] == args[key]
            ),
          ];
        } else {
          item = [
            ...item.filter((val: any) => val[a] != null ?
              val[a].toLowerCase().includes(args[key]) : val[a] = ''
            ),
          ];
        }
      }

    });

    return item;
  }

}
