import { Component, OnInit } from '@angular/core';
import { ChangePasswordComponent } from '../change-password.component';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-d-change-password',
  templateUrl: './d-change-password.component.html',
  styleUrls: ['./d-change-password.component.css']
})
export class DChangePasswordComponent extends ChangePasswordComponent implements OnInit {
  public key: any;
  ngOnInit(): void {
    if(this.key && this.key?.modal) {
      this.bsModalRef = this.injector.get<BsModalRef>(BsModalRef);
    }
    this.initFormData();
  }

}
