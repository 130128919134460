import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './@shared/shared.module';
import {PusherService} from "./@shared/services/pusher.service";
import { GeneralConfigService } from './@shared/services/general-config.service';
import { environment } from 'src/environments/environment';
import { RedirectGuard } from './auth/guard';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    SharedModule
  ],
  providers: [PusherService, 
    GeneralConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [GeneralConfigService]
    }, RedirectGuard,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function appInit(appConfigService: GeneralConfigService) {
  let config = appConfigService.load();
  config.then((value:any) => {
    environment.isMobile = value;
  })
  return () => config;
}
