import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

//common services
import { MessageService } from "./message/message.service";
import { SharedService } from "./services/shared.service";
import { WebserviceService } from "./services/webservice.service";
import { CommonService } from './services/common.service';
import { LoaderService } from './services/loader.service';

// ngx-bootstrap
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AccordionModule } from 'ngx-bootstrap/accordion';

//toster
import { ToastrModule, ToastrService } from 'ngx-toastr';

//pipes
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeURLPipe } from './pipes/safe-url.pipe';
import { SearchDataPipe } from './pipes/search-data.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';

//shared components
import { LoaderComponent } from './components/loader/loader.component';

import { RulesComponent } from './components/rules/rules.component';
import { MRulesComponent } from './components/rules/m-rules/m-rules.component';
import { DRulesComponent } from './components/rules/d-rules/d-rules.component';

import { BetSlipComponent } from './components/bet-slip/bet-slip.component';
import { MBetSlipComponent } from './components/bet-slip/m-bet-slip/m-bet-slip.component';
import { DBetSlipComponent } from './components/bet-slip/d-bet-slip/d-bet-slip.component';
import { BetListComponent } from './components/bet-list/bet-list.component';
import { MBetListComponent } from './components/bet-list/m-bet-list/m-bet-list.component';
import { DBetListComponent } from './components/bet-list/d-bet-list/d-bet-list.component';
import { MarketAnalysisComponent } from './components/market-analysis/market-analysis.component';
import { ForceChangePasswordComponent } from './components/force-change-password/force-change-password.component';
//import { LoginComponent } from '../auth/login/login.component';
import { SidebarComponent } from '../layout/sidebar/sidebar.component';
import { TopnavComponent } from '../layout/topnav/topnav.component';
import { FilterPipe } from './pipes/filter.pipe';
import { FooterComponent } from '../layout/footer/footer.component';
import { UpcomingComponent } from '../layout/upcoming/upcoming.component';
import { FeaturedEventsComponent } from './components/featured-events/featured-events.component';
import { StakeComponent } from './components/stake/stake.component';
import { MStakeComponent } from './components/stake/m-stake/m-stake.component';
import { DStakeComponent } from './components/stake/d-stake/d-stake.component';
import { TableComponent } from './components/table/table.component';
import { DTableComponent } from './components/table/d-table/d-table.component';
import { MTableComponent } from './components/table/m-table/m-table.component';
import { MarketRuleComponent } from './components/market-rule/market-rule.component';
import { MMarketRuleComponent } from './components/market-rule/m-market-rule/m-market-rule.component';
import { DMarketRuleComponent } from './components/market-rule/d-market-rule/d-market-rule.component';


@NgModule({
  declarations: [
    SafeHtmlPipe,
    SafeURLPipe,
    SearchDataPipe,
    FormatDatePipe,
    FilterPipe,
    LoaderComponent,
    BetSlipComponent,
    MBetSlipComponent,
    DBetSlipComponent,
    BetListComponent,
    MBetListComponent,
    DBetListComponent,
    MarketAnalysisComponent,
    ForceChangePasswordComponent,
    // LoginComponent,
    RulesComponent,
    MRulesComponent,
    DRulesComponent,
    //MarketRulesComponent,
    SidebarComponent,
    StakeComponent,
    MStakeComponent,
    DStakeComponent,
    TopnavComponent,
    FooterComponent,
    UpcomingComponent,
    FeaturedEventsComponent,
    TableComponent,
    MTableComponent,
    DTableComponent,
    MarketRuleComponent,
    MMarketRuleComponent,
    DMarketRuleComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: 'toast-top-center',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
      maxOpened: 1,
      autoDismiss: true,
      enableHtml: true
    }),
    ModalModule.forRoot(),
    TabsModule.forRoot(),
    CollapseModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    AccordionModule.forRoot(),
  ],
  exports: [
    RouterModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    ToastrModule,
    ModalModule,
    TabsModule,
    CollapseModule,
    TooltipModule,
    TypeaheadModule,
    AccordionModule,
    SafeHtmlPipe,
    SafeURLPipe,
    SearchDataPipe,
    FormatDatePipe,
    FilterPipe,
    LoaderComponent,
    BetSlipComponent,
    MBetSlipComponent,
    DBetSlipComponent,
    BetListComponent,
    MBetListComponent,
    DBetListComponent,
    MarketAnalysisComponent,
    ForceChangePasswordComponent,
    // LoginComponent,
    SidebarComponent,
    StakeComponent,
    MStakeComponent,
    DStakeComponent,
    TopnavComponent,
    FooterComponent,
    UpcomingComponent,
    RulesComponent,
    MRulesComponent,
    DRulesComponent,
    FeaturedEventsComponent,
    TableComponent,
    MTableComponent,
    DTableComponent,
    MarketRuleComponent,
    MMarketRuleComponent,
    DMarketRuleComponent,
  ],
  providers: [MessageService, SharedService, WebserviceService, CommonService, LoaderService, {
    provide: ToastrService
  }],
})
export class SharedModule { }
