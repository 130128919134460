import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchData'
})
export class SearchDataPipe implements PipeTransform {

  transform(item: any, args: any): any {
    // console.log(args);

    if (!item) return [];
    if(args) {
      Object.keys(args).some(key => {
        let a = key.split('-')[0];  //actual key
        let b = key.split('-')[1];  //filter type eq(equalTo) and in(include)
        args[key] = (args[key] != null) ? args[key] + "".toLowerCase() : null;

        if (args[key] != '' && args[key] != null) {
          if (b == 'eq') {
            item = [
              ...item.filter((val: any) =>
                val[a].toString().toLowerCase() == args[key].toString().toLowerCase()
              ),
            ];
          } else {
            item = [
              ...item.filter((val: any) => val[a] != null ?
                val[a].toString().toLowerCase().includes(args[key].toString().toLowerCase()) : val[a] = ''
              ),
            ];
          }
        }

      });
    }

    return item;
  }
  // transform(items: any[], searchText: string): any[] {
  //   if (!items) return [];
  //   if (!searchText) return items;

  //   return items.filter(item => {
  //     return Object.keys(item).some(key => {
  //       return String(item[key]).toLowerCase().includes(searchText.toLowerCase());
  //     });
  //   });
  //  }

}
