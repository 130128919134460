import { Component, OnInit } from '@angular/core';
import { StakeComponent } from '../stake.component';

@Component({
  selector: 'app-d-stake',
  templateUrl: './d-stake.component.html',
  styleUrls: ['./d-stake.component.css']
})
export class DStakeComponent extends StakeComponent implements OnInit {

  ngOnInit(): void {
    this.init();
  }

}
