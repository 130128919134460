import { Component, Input, OnInit } from '@angular/core';
import { TableComponent } from '../table.component';

@Component({
  selector: 'app-d-table',
  templateUrl: './d-table.component.html',
  styleUrls: ['./d-table.component.css']
})
export class DTableComponent extends TableComponent implements OnInit {
  @Input() data!: any;

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.callEventTypeApi = this.data?.callEventTypeApi ? this.data?.callEventTypeApi : false;
    // if(this.callEventTypeApi == true) {
    //   this.callEventTypeApi = false;
    //   this.getEventTypeIdsData();
    // }
    this.pageData = this.data?.elements;
    this.elements = this.data?.elements?.data;
    this.headElements = this.data?.headElements;
    this.rowClassEnabled = this.data?.rowClassEnabled;
    this.searchDt = this.data?.searchDt;
    this.showPagination = this.data?.showPagination
    this.cdRef.detectChanges();
    
  }

}
