import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  @Input() isFpLoader: boolean = false;   //fp:full page loader
  env = environment;
  
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
  }

}
