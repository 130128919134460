import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BaseClass } from 'src/app/@shared/base-class/base-class';
import { IbaseService } from 'src/app/@shared/base-service/ibase-service';
import { MessageService } from 'src/app/@shared/message/message.service';
import { CommonService } from 'src/app/@shared/services/common.service';
import { WebserviceService } from 'src/app/@shared/services/webservice.service';

@Component({
  selector: 'app-change-password',
  template: `
  <app-m-change-password *ngIf="env.isMobile"></app-m-change-password>
  <app-d-change-password *ngIf="!env.isMobile"></app-d-change-password>
  `,
  styles: []
})
export class ChangePasswordComponent extends BaseClass<any> implements OnInit {
  public bsModalRef!: BsModalRef;

  formData!: FormGroup;
  @Input() isModal!: any;
  errors: any;
  submitted = false;
  @Output() openLogin = new EventEmitter();

  constructor(public service: WebserviceService,
    protected fb: FormBuilder,
    public router: Router,
    public _message: MessageService,
    public _common: CommonService,
    public injector: Injector
  ) {
    super(document);
  }

  ngOnInit(): void {
  }

  initFormData() {
    this.formData = this.fb.group({
      old_password: ['', Validators.required],
      new_password: ['', [Validators.required, Validators.minLength(6)]],
      new_password_re: ['', Validators.required],
    }, {
      validator: this._common.mustMatch('new_password', 'new_password_re'),
    });
  }

  getServices(): IbaseService<any> {
    return this.service;
  }

  get f() {
    return this.formData.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.formData.invalid) {
      return;
    }

    this.isLoad = true;
    let req = this.JSON_to_URLEncoded(this.formData.value);
    this.getServices().Post(`${this.env.baseUrl + this.api.changePass}`, req).subscribe(
      (res) => {
        if (res.status == true) {
          this._message.succmessage(res.data.message);

          // this.router.navigate([`${this.env.isMobile ? '/m/login' : '/login'}`]);
          // this.openLogin.emit('')

          if (this.env.host.guestEnable) {
            this.router.navigate([`${this.env.isMobile ? '/m/home' : '/home'}`]);
          } else {
            this.router.navigate([`${this.env.isMobile ? '/m/login' : '/login'}`]);
          }
          if(this.bsModalRef) {
            this.bsModalRef.hide();
          }
          // window.location.reload();

        } else {
          this.errors = res.data;
          this._message.errmessage(res.error.message);
        }
        this.isLoad = false;
      },
      (err) => {
        console.log(err);
        this.isLoad = false;
      });
  }

}
