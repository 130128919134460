<div id="app">
    <app-loader [isFpLoader]="true"></app-loader>
</div>

<button *ngIf="key" type="button" class="close btn-close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
</button>

<div *ngIf="key?.modal || router.url.includes('login')" class="login" [ngClass]="{'loginModal' : key?.modal}">
    <div class="wrapper">
        <div class="container-fluid">
            <div class=" row">
                <div class="col-md-12">
                    <div class="loginInner1">
                        <div class="log-logo m-b-20 text-center"><img src="assets/img/{{env.host.name}}/logo.png" class="logo-login">
                        </div>
                        <div class="featured-box-login featured-box-secundary default">
                            <h4 class="text-center">LOGIN <i class="fas fa-hand-point-down"></i></h4>
                            <!---->
                            <form autocomplete="off" [formGroup]="formGroup" (ngSubmit)="onLogin()">
                                <div class="form-group m-b-20">
                                    <input name="User Name" formControlName="username" [placeholder]="key?.modal ? 'User Name / Mobile Number' : 'User Name'"
                                        type="text" class="form-control" aria-required="true" aria-invalid="false"> <i
                                        class="fa fa-user"></i>
                                    <!----> <small *ngIf="isSubmit && f.username?.errors?.required" class="text-danger" >Required</small>
                                </div>
                                <div class="form-group m-b-20">
                                    <input name="Password" formControlName="password" placeholder="Password"
                                        type="password" class="form-control" aria-required="true" aria-invalid="false">
                                    <i class="fa fa-key"></i>
                                    <!----> <small *ngIf="isSubmit && f.password?.errors?.required" class="text-danger" >Required</small>
                                </div>

                                <div class="form-group text-center" *ngIf="env.host.signUpActive">
                                    <a class="fp" href="javascript:void(0)" (click)="openModal(forgotPasswordModal)">Forgot Password</a>
                                 </div>

                                <div class="form-group text-center" [ngClass]="(env.host.demoLogin || env.host.social.isShow) ? 'mb-1' : 'mb-0'">
                                    <button type="submit" class="btn btn-submit btn-login" [ngClass]="{'disabled' : isLoad}">
                                        Login
                                        <i *ngIf="!isLoad" class=" ml-2 fa fa-sign-in-alt"></i>
                                        <i *ngIf="isLoad" class=" ml-2 fa fa-spinner fa-spin"></i>
                                    </button>
                                </div>
                                <div class="form-group text-center" [ngClass]="env.host.social.isShow ? 'mb-1' : 'mb-0'" *ngIf="env.host.demoLogin">
                                    <button type="button" (click)="loginWIthDemoId()" class="btn btn-submit btn-login" [ngClass]="{'disabled' : isLoadDemo}">
                                        Login with Demo ID
                                        <i *ngIf="!isLoadDemo" class=" ml-2 fa fa-sign-in-alt"></i>
                                        <i *ngIf="isLoadDemo" class=" ml-2 fa fa-spinner fa-spin"></i>
                                    </button>
                                </div>
                                <div class="form-group text-center mt-1 mb-1" *ngIf="env.host.downloadApk != ''">
                                    <a [href]="env.host.downloadApk" target="_blank" class="btn btn-primary btn-block"> Download APK
                                        <svg height="20px" width="20px" version="1.1" id="x32" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512" xml:space="preserve">
                                        <style type="text/css">
                                            .st0{fill:#fff;}
                                        </style>
                                        <g>
                                            <path class="st0" d="M335.413,62.069l32.308-47.853c2.808-4.158,1.702-9.856-2.455-12.664c-4.158-2.807-9.856-1.701-12.664,2.456
                                                L319.235,53.43C299.968,44.637,278.554,39.737,256,39.737c-22.553,0-43.967,4.9-63.234,13.694L159.398,4.008
                                                c-2.807-4.157-8.506-5.263-12.663-2.456c-4.158,2.808-5.263,8.505-2.456,12.664l32.308,47.853
                                                c-40.562,24.799-68.666,67.984-72.608,117.976h304.044C404.08,130.053,375.975,86.87,335.413,62.069z M193.951,133.99
                                                c-11.754,0-21.283-9.528-21.283-21.283c0-11.755,9.529-21.283,21.283-21.283c11.754,0,21.283,9.528,21.283,21.283
                                                C215.234,124.462,205.705,133.99,193.951,133.99z M318.05,133.99c-11.754,0-21.283-9.528-21.283-21.283
                                                c0-11.755,9.529-21.283,21.283-21.283c11.754,0,21.283,9.528,21.283,21.283C339.333,124.462,329.804,133.99,318.05,133.99z"></path>
                                            <path class="st0" d="M101.653,244.206v175.82c0,13.804,11.28,25.084,25.084,25.084h41.472v41.046
                                                c0,14.198,11.615,25.844,25.844,25.844c14.198,0,25.844-11.645,25.844-25.844V445.11h72.971v41.046
                                                c0,14.198,11.615,25.844,25.844,25.844c14.199,0,25.844-11.645,25.844-25.844V445.11h36.059c13.774,0,25.084-11.28,25.084-25.084
                                                v-175.82v-37.245H101.653V244.206z"></path>
                                            <path class="st0" d="M46.75,206.961c-16.426,0-29.864,13.438-29.864,29.864V335.2c0,16.425,13.439,29.864,29.864,29.864
                                                c16.425,0,29.864-13.439,29.864-29.864v-98.375C76.614,220.4,63.175,206.961,46.75,206.961z"></path>
                                            <path class="st0" d="M465.251,206.961c-16.425,0-29.864,13.438-29.864,29.864V335.2c0,16.425,13.439,29.864,29.864,29.864
                                                c16.425,0,29.864-13.439,29.864-29.864v-98.375C495.114,220.4,481.675,206.961,465.251,206.961z"></path>
                                        </g>
                                        </svg>
                                    </a>
                                </div>
                                <div class="social_links" *ngIf="env.host.social.isShow">
                                  <a *ngIf="env.host.social.fbLink != ''" [href]="env.host.social.fbLink" target="_blank">
                                    <img class="img-fluid" src="https://cdn-icons-png.flaticon.com/512/2504/2504903.png">
                                  </a>
                                  <a *ngIf="env.host.social.instaLink != ''" [href]="env.host.social.instaLink" target="_blank">
                                    <img class="img-fluid" src="https://cdn-icons-png.flaticon.com/512/2111/2111463.png">
                                  </a>
                                  <a *ngIf="env.host.social.telLink != ''" [href]="env.host.social.telLink" target="_blank">
                                    <img class="img-fluid" src="https://cdn-icons-png.flaticon.com/512/2504/2504941.png">
                                  </a>
                                </div>
                                <div class="form-group text-center mb-0" *ngIf="env.host.social.isShow">
                                    <p class="whatsapp_text" *ngIf="env.host.whatsapptext != ''">
                                      {{env.host.whatsapptext}}
                                    </p>
                                    <a *ngIf="env.host.social.wpLink != ''" [href]="env.host.social.wpLink" target="_blank" class="btn whatsappIcon">
                                        <img class="img-fluid" src="https://cdn-icons-png.flaticon.com/512/3992/3992601.png">
                                        Get ID on Whatsapp
                                    </a>
                                    <a *ngIf="env.host.social.wpLink == ''" href="javascript:void(0)" class="btn whatsappIcon">
                                    <img class="img-fluid" src="https://cdn-icons-png.flaticon.com/512/3992/3992601.png">
                                    Get ID on Whatsapp
                                    </a>
                                </div>
                                <small class="recaptchaTerms">This site is protected by reCAPTCHA and the Google
                                    <a href="javascript:void(0)">Privacy Policy</a> and
                                    <a href="javascript:void(0)">Terms of Service</a> apply.
                                </small>
                                <p style="line-height: 2;" class="mt-1 align-center" *ngIf="env.host.email != ''"><a href="mailto:{{env.host.email}}" class="mail-link">{{env.host.email}}</a></p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <div class="extra_content">
  <p>{{env.host.content}}</p>
</div> -->

<iframe *ngIf="key?.signUpModal" class="custom-ifrm embed-responsive-item" scrolling="yes" [src]="env.host.iframeDomainUrl + 'signup/' + env.domain + env.trafficTag | safeURL" allowfullscreen="true" ></iframe>

<!-- Forgot Password -->
<ng-template #forgotPasswordModal>
    <div class="modal-header">
      <!-- <h4 class="modal-title pull-left">Forgot Password</h4> -->
      <button type="button" class="close btn-close pull-right" aria-label="Close" (click)="modalRef?.hide()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body p-0">
      <iframe class="custom-ifrm embed-responsive-item" scrolling="yes" [src]="env.host.iframeDomainUrl + 'forgot-password/' +env.domain | safeURL" allowfullscreen="true" ></iframe>
    </div>
  </ng-template>
  <!-- /Forgot Password -->
