import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BetSlipComponent } from '../bet-slip.component';

@Component({
  selector: 'app-m-bet-slip',
  templateUrl: './m-bet-slip.component.html',
  styleUrls: ['./m-bet-slip.component.css']
})
export class MBetSlipComponent extends BetSlipComponent implements OnInit {
  @Input() data!: any;
  @Input() exposLisDis!: any;
  @Output() closeBet = new EventEmitter();
  @Output() updateData = new EventEmitter();
  @Output() changeExpo = new EventEmitter();
  public isIn: boolean = false;

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.betData = this.data;
    this.expo = this.exposLisDis;
    this.setDataOnChange(this.data, this.exposLisDis)
  }

  ngAfterContentChecked() {
    this.gotoOpenBetSlip();
  }

  gotoOpenBetSlip() {
    let location = `goto-${this.betData?.market_id}-${this.betData?.selection_id}`
    let el = document.getElementById(location) as HTMLElement;
    if (el && this.isIn == false) {
      this.gotoDiv(location);
      this.isIn = true;
    }
  }

}
