import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { RedirectGuard } from "./auth/guard";

const routes: Routes = [
  // {
  //   path: "",
  //   redirectTo: "home",
  //   pathMatch: "full",
  // },
  { path: '', pathMatch: 'full', children: [], canActivate: [RedirectGuard] },
  {
    path: "login",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "m/login",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  {
    path: "m",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
