import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BaseClass } from '../../base-class/base-class';
import { IbaseService } from '../../base-service/ibase-service';
import { MessageService } from '../../message/message.service';
import { CommonService } from '../../services/common.service';
import { SharedService } from '../../services/shared.service';
import { WebserviceService } from '../../services/webservice.service';

@Component({
  selector: 'app-bet-slip',
  template: `
  <app-m-bet-slip [data]="data" [exposLisDis]="exposLisDis" *ngIf="env.isMobile"></app-m-bet-slip>
  <app-d-bet-slip [data]="data" [exposLisDis]="exposLisDis" *ngIf="!env.isMobile"></app-d-bet-slip>
  `,
  styles: []
})
export class BetSlipComponent extends BaseClass<any> implements OnInit {
  @Input() data!: any;
  @Input() exposLisDis!: any;
  @Output() closeBet = new EventEmitter();
  @Output() updateData = new EventEmitter();
  @Output() changeExpo = new EventEmitter();

  public betData: any;
  public expo: any = {};

  oddsInputLogic = [
    { min: 1, max: 2, value: 0.01, decimals: 2 },
    { min: 2, max: 3, value: 0.02, decimals: 2 },
    { min: 3, max: 4, value: 0.05, decimals: 2 },
    { min: 4, max: 6, value: 0.1, decimals: 2 },
    { min: 6, max: 10, value: 0.2, decimals: 2 },
    { min: 10, max: 20, value: 0.5, decimals: 1 },
    { min: 20, max: 30, value: 1, decimals: 0 },
    { min: 30, max: 50, value: 2, decimals: 0 },
    { min: 50, max: 100, value: 5, decimals: 0 },
    { min: 100, max: 1000, value: 10, decimals: 0 },
  ]

  constructor(public service: WebserviceService,
    public _message: MessageService,
    public _shared: SharedService,
    public _common: CommonService
  ) { super(document); }

  ngOnInit(): void {
  }

  getServices(): IbaseService<any> {
    return this.service;
  }

  onChangeOdds(key: string) {
    if (this.betData.key != 'fancy') {
      let isChecked = false;
      this.betData.price = Number(this.betData.price);
      this.oddsInputLogic.forEach(element => {
        if (this.betData.price >= element.min && this.betData.price < element.max && !isChecked) {
          if (key == 'up' && this.betData.price < 999.99) {
            this.betData.price += element.value
          } else if (this.betData.price > 1.01) {
            this.betData.price -= element.value
          }
          this.betData.price = this.betData.price.toFixed(element.decimals);
          isChecked = true;
        }
      });
      this.validateField();
    }
  }
  onChangeStake(key: string) {
    this.betData.stake = Number(this.betData.stake);
    if (key == 'up' && this.betData.stakes.length > 0) {
      this.betData.stake += Number(this.betData.stakes[0].amount);
    } else if (key == 'down' && this.betData.stakes.length > 0 && this.betData.stake > 0) {
      this.betData.stake -= Number(this.betData.stakes[0].amount);
    }
    this.validateField();
  }

  onChangeExpo() {

    if (this.betData?.runners) {
      this.betData?.runners.forEach((element: any, i: any) => {
        let odds = this.betData.key == 'book_makers' ? Number(this.betData.price) / 100 : Number(this.betData.price) - 1;
        let sId = element.selectionId ? element.selectionId : element.selection_id;
        let betExpo = this.expo[this.betData?.market_id] ? this.expo[this.betData?.market_id][sId]?.expose : 0;

        if (this.betData.selection_id == sId) {
          element["pnlNext"] = (this.betData.side.includes("b")) ?
            (Number(betExpo) + (Number(odds)) * Number(this.betData.stake)).toFixed(2) :
            (Number(betExpo) - (Number(odds)) * Number(this.betData.stake)).toFixed(2);
        } else {
          element["pnlNext"] = (this.betData.side.includes("b")) ?
            (Number(betExpo) - Number(this.betData.stake)).toFixed(2) :
            (Number(betExpo) + Number(this.betData.stake)).toFixed(2);
        }
      });
      this.changeExpo.emit(this.betData)
    }
  }

  digitOnly(e: any) {
    this.validateField();
    return e.charCode >= 48 && e.charCode <= 57
  }

  validateField() {
    // console.log(this.betData?.stake);    
    let price = this.betData?.price;
    let amnt = this.betData?.stake;
    if (price != undefined && price != null && price != 0 && amnt != undefined && amnt != null && amnt != 0) { this.betData.disabled = false } else { this.betData.disabled = true }
    this.onChangeExpo();
  }

  onAmountChange(e: any) {
    this.betData.stake as number;
    if ((this.betData.stake != null || this.betData.stake != undefined) && this.betData.stake < this.betData?.max) {
      this.betData.stake += Number(e.amount);
    } else {
      this.betData.stake = Number(e.amount);
    }
    this.validateField();
  }

  onMinMaxAmountChange(e:any) {
    this.betData.stake as number;
    this.betData.stake = Number(e);
    this.validateField();
  }
  onAllInAmountChange() {
    let user = this.getLocalItem('user');
    user.available_balance = (user?.available_balance == null) ? 0 : user?.available_balance;
    this.betData.stake = (this.roundVal(user?.available_balance) >= this.roundVal(this.betData?.max)) ? Number(this.betData?.max) : this.roundVal(user?.available_balance)
    this.validateField();
  }
  onClear() {
    this.betData.stake = null;
    this.validateField();
  }

  orderPlaced() {
    if (this.isLogin()) {
      this.isLoad = true;
      this.betData.isSubmitted = true;
      let tempReq = {
        "bet_details[market_id]": this.betData.market_id,
        "bet_details[selection_id]": this.betData.selection_id,
        "bet_details[side]": this.betData.side,
        "bet_details[rate]": this.betData.price,
        "bet_details[price]": this.betData.rate,
        "bet_details[fancy_rate]": this.betData.fancy_rate,
        "bet_details[stake]": this.betData.stake,
        "bet_details[event_id]": this.betData.event_id,
        "bet_details[token]": this.betData.token,
      }
      let req = this.JSON_to_URLEncoded(tempReq)

      // console.log(req);
      this.betData.isSubmitted = false
      // return;

      this.betData.disabled = true;
      this.getServices().Post(`${this.env.baseUrl + this.api.placeBet}`, req).subscribe(
        (res) => {
          // console.log(res);
          this.isLoad = false;
          this.betData.isSubmitted = false;
          if (res?.data?.status && res?.data?.status == 1) {
            this.betData.isClosed = true;
            this._message.succmessage(res?.data?.message);
            this._shared.emitbetSlipRes(res.data);

          } else {

            this._message.errmessage(res.data.error);
          }
          this.closeBet.emit("");
          this.betData.token = res?.data?.token;
          this.updateData.emit(res?.data?.token);
          this.betData = this.data = undefined;
        },
        (err) => {
          this.betData.isSubmitted = false;
          this.betData.disabled = false;
          this.closeBet.emit("");
        });
    } else {
      this._common.openLogin();
      // this._shared.emitLoginModal({ 'login': true });
    }
  }

  onCloseBet(e: any) {
    this.betData.isClosed = true;
    this.closeBet.emit("");
  }

  ngOnChanges() {
    this.setDataOnChange(this.data, this.exposLisDis);
  }

  setDataOnChange(data: any, expo: any) {
    this.betData = data;
    this.expo = expo;

    if (this.betData && this.betData?.runners)
      this.betData?.runners.forEach((element: any, i: any) => {
        element['pnlNext'] = '';
        element['pnl'] = (this.betData.data.exposes) ? this.betData.data.exposes[i].expose : 0;
        element['average'] = (this.betData.data.exposes) ? this.betData.data.exposes[i].average : '';
      });

    if (this.betData?.stake && this.betData?.stake != 0) {
      this.validateField();
    }
  }

}
