import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseClass } from '../../base-class/base-class';
import { IbaseService } from '../../base-service/ibase-service';
import { SharedService } from '../../services/shared.service';
import { WebserviceService } from '../../services/webservice.service';

@Component({
  selector: 'app-bet-list',
  template: `
  <app-m-bet-list *ngIf="env.isMobile"></app-m-bet-list>
  <app-d-bet-list *ngIf="!env.isMobile"></app-d-bet-list>
  `,
  styles: []
})
export class BetListComponent extends BaseClass<any> implements OnInit {
  @Output() totalBets = new EventEmitter();

  public data: any = {
    all: [],
    matched: [],
    unMatched: [],
  };

  public eventList: any[] = [];
  selectedEvent: any = '';
  public matchedTotal = 0;
  public isDetail:boolean = false;

  constructor(public service: WebserviceService,
    protected actRoute: ActivatedRoute,
    protected _shared: SharedService
  ) {
    super(document);
  }

  constructorCallData() {
    const sub1 = this._shared.updateExpoPusher.subscribe((res: any) => {
      this.updateExpoByPusher(res);
    });
    this.subs.push(sub1);

    const sub2 = this._shared.sharedupdateUserAccPusher.subscribe((res: any) => {
      this.updateBetResult(res);
    });
    this.subs.push(sub2);

    const sub3 = this._shared.sharedbetSlipRes.subscribe((res: any) => {
      this.updateExpoByBetPlaced(res);
    })
    this.subs.push(sub3);

    const sub4 = this._shared.sharedupdateBetList.subscribe((res: any) => {
      this.getData();
    });
    this.subs.push(sub3);

  }

  ngOnInit(): void {
  }

  getData() {
    this.getServices().Get(`${this.env.baseUrl + this.api.orderList}`).subscribe(
      (res) => {
        if (res.status == true) {
          //console.log(res);
          res.data.orders = this.UTC_to_Local_timeZone(res.data.orders.data);
          this.data.all = res.data.orders.filter((e: any) => { return e.status != 3 });;
          this.data.matched = this.getMatchedBets(this.data.all);
          this.data.unMatched = this.getUnMatchedBets(this.data.all);
          

          let dt: any[] = [];
          let ids: any = [];
          let totalBets = 0;
          res.data.orders.forEach((element: any) => {
            if (!ids.includes(element.event_id)) {
              dt.push({ id: element.event_id, name: element.event_name })
            }
            ids.push(element.event_id);
          });
          if (this.actRoute.snapshot.paramMap.get('id')) {
            this.isDetail = true;
            // for detail page
            const matchedGrouped = this.groupBy(this.data.matched, (dat: any) => dat.event_id);
            dt.forEach(element => {
              if (element.id == this.actRoute.snapshot.paramMap.get('id')) {
                this.eventList = [element];
                let detailPageMatchedOrders = matchedGrouped.get(element.id)
                totalBets = detailPageMatchedOrders.length;
                this.matchedTotal = totalBets;
              }
            });
          } else {
            // for other pages
            this.eventList = dt;
            totalBets = this.data.matched.length;
            this.matchedTotal = totalBets;
          }
          this.totalBets.emit(totalBets)

          
          

          this.selectedEvent = this.actRoute.snapshot.paramMap.get('id') ? this.actRoute.snapshot.paramMap.get('id') : (this.eventList.length > 0) ? this.eventList[0]?.id : ''
        }
      },
      (err) => {
        console.log(err);
      });
  }

  getMatchedBets(res: any) {
    const grouped = this.groupBy(res, (dat: any) => dat.is_matched);
    return grouped.get(1) ? grouped.get(1) : [];
  }

  getUnMatchedBets(res: any) {
    const grouped = this.groupBy(res, (dat: any) => dat.is_matched);
    return grouped.get(0) ? grouped.get(0) : [];
  }

  genIdArray() {
    let idArr: any[] = [];
    this.data.all.forEach((element: any) => {
      idArr.push(element.id);
    });
    return idArr;
  }

  appendMarketType(items: any, betId: any) {
    items.forEach((element: any) => {
      element["id"] = Array.isArray(betId) ? betId[0]?.id : betId != '' ? betId : '';
      if (element.market_type_id == 'BOOK_MAKER' || element.market_type_id == 'MATCH_ODDS') {
        element["market_type"] = '';
      } else {
        element["market_type"] = `(${element.market_type_id})`;
      }
    });

  }

  findByBetId(items: any, betId: any) {
    let data: any[] = [];
    const grouped = this.groupBy(items, (dat: any) => dat.bet_id);
    data = grouped.get(betId) ? grouped.get(betId) : [];
    return data;
  }

  setData(items: any) {
    let mData = this.getMatchedBets([items]);
    let umData = this.getUnMatchedBets([items]);
    this.data.matched = [...mData, ...this.data.matched];
    this.data.unMatched = [...umData, ...this.data.unMatched];
    this.data.all = [...[items], ...this.data.all];
    if (this.actRoute.snapshot.paramMap.get('id')) {
      // for detail page
      let data: any[] = [];
      const grouped = this.groupBy(this.data.matched, (dat: any) => dat.event_id);
      data = grouped.get(this.actRoute.snapshot.paramMap.get('id')) ? grouped.get(this.actRoute.snapshot.paramMap.get('id')) : [];
      this.totalBets.emit(this.matchedTotal + data.length);
      this.matchedTotal = this.matchedTotal + data.length;
    }else{
      this.totalBets.emit(this.data.matched.length);
      this.matchedTotal = this.data.matched.length;
    }
  }

  updateExpoByBetPlaced(res: any) {
    let idArr: any[] = this.genIdArray();
    if (res) {
      if (!idArr.includes(res.bet.id)) {
        this.setData(res.bet);
      }
    }
  }

  updateExpoByPusher(res: any) {
    let idArr: any[] = this.genIdArray();
    if (res) {

      if (res.op == 'A') {
        console.log("bet Added");
        if (!idArr.includes(res.bet_id)) {
          // bet add
          if (res.bets.market_bets != null && res.bets.market_bets.length > 0) {
            this.appendMarketType(res.bets.market_bets, res.bet_id);
            this.setData(res.bets.market_bets);

          } else if (res.bets.fancy_bets != null && res.bets.fancy_bets.length > 0) {
            this.appendMarketType(res.bets.fancy_bets, res.bet_id);
            this.setData(res.bets.fancy_bets);
          }
        }

      } else if (res.op == 'U') {
        console.log("bet updated");

        // bet update
        if (res.bets.market_bets != null && res.bets.market_bets.length > 0) {

          let fbBetId = this.findByBetId(this.data.matched, res.bet_id);
          if (fbBetId.length > 0) {
            fbBetId.forEach(element => {
              element = res.bets.market_bets[0];
            });
          } else {
            this.appendMarketType(res.bets.market_bets, res.bet_id);
            this.data.matched = [...fbBetId, ...this.data.matched];
            this.data.all = [...res.bets.market_bets, ...this.data.all];
          }
          let fbBetId1 = this.findByBetId(this.data.unMatched, res.bet_id);
          if (fbBetId1.length > 0) {
            fbBetId.forEach((element, i, obj) => {
              if (element.stake_remaining == 0) {
                obj.splice(i, 1);
              } else {
                element = res.bets.market_bets[0]
              }
            });
          }
        }

      } else if (res.op == 'D') {
        console.log("bet deleted");

        // bet deleted
        this.data.all.forEach((element: any, i: any) => {
          if (element.id == res.bet_id) {
            this.data.all.splice(i, 1);
          }
        });

        this.data.matched = [];
        this.data.unMatched = [];
        this.setData(this.data.all);

      } else if (res.op == 'RR') {
        console.log("bet RR");

        this.getData();
      } else if (res.op == 'R') {
        console.log("bet result");
        let tDt: any[] = [];
        this.data.all.forEach((element: any, i: any) => {
          if (element.market_id == res?.m_id) {
            tDt.push(i);
          }
        });
        for (var i = tDt.length - 1; i >= 0; i--) {
          this.data.all.splice(tDt[i], 1);
        }

        this.data.matched = [];
        this.data.unMatched = [];
        this.setData(this.data.all);
      }
      // }
    }
  }

  updateBetResult(res: any) {
    if (res.op == 'R') {
      console.log("bet result");
      let tDt: any[] = [];
      this.data.all.forEach((element: any, i: any) => {
        if (element.market_id == res?.m_id) {
          tDt.push(i);
        }
      });
      for (var i = tDt.length - 1; i >= 0; i--) {
        this.data.all.splice(tDt[i], 1);
      }

      this.data.matched = [];
      this.data.unMatched = [];
      this.setData(this.data.all);
    }
  }

  getServices(): IbaseService<any> {
    return this.service;
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

}
