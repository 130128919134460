import { Component, OnInit } from '@angular/core';
import { BaseClass } from 'src/app/@shared/base-class/base-class';
import { IbaseService } from 'src/app/@shared/base-service/ibase-service';
import { MessageService } from 'src/app/@shared/message/message.service';
import { SharedService } from 'src/app/@shared/services/shared.service';
import { WebserviceService } from 'src/app/@shared/services/webservice.service';

@Component({
  selector: 'app-stake',
  template: `
  <app-m-stake *ngIf="env.isMobile"></app-m-stake>
  <app-d-stake *ngIf="!env.isMobile"></app-d-stake>
  `,
  styles: []
})
export class StakeComponent extends BaseClass<any> implements OnInit {
  public stacks: any;
  public stArr: any[] = [];

  constructor(public service: WebserviceService,
    private _message: MessageService,
    public sharedService: SharedService
  ) {
    super(document);
  }

  ngOnInit(): void {
  }

  init() {
    this.stacks = this.getLocalItem('stakes');
    this.stArr = Array(8).fill(0);
  }

  getServices(): IbaseService<any> {
    return this.service;
  }

  setBtnVal() {
    this.isLoad = true;
    let req = this.JSON_to_URLEncoded(this.stacks)
    this.service.Post(`${this.env.baseUrl + this.api.stakesUpdate}`, req)
      .subscribe(
        (res) => {
          if (res.status == true) {
            this._message.succmessage("Stack Updated successfully");
            localStorage.setItem(`${this.env.panel}stakes`, JSON.stringify(this.stacks))
          }
          this.isLoad = false;
        },
        (err) => {
          this.isLoad = false;
          this._message.errmessage(err);
        }
      );

  }

}
